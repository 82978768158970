.fadeInUp {
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes fadeInUp {
    from {
        transform: translateY(50px);
        opacity: 0;
    }
  
    to {
        transform: translateY(0);
        opacity: 1;
    }
}